<template>
    <div class="payment">
        <s-header v-if="showBack" :name="$t('LAN.payhelp_title')"></s-header>


        <div class="top">
            <p class="zfbt">
                <span class="bcxf">{{ $t("LAN.pay_title") }}</span>
                <span class="change">
                    <div class="change_t">
                        <img :src="selectCountryData.countryLogo" alt="" />
                        <a @click="languageIs()" >{{ $t(`LOCAL.${lang}`) }}</a>
                    </div>
                </span>
            </p>
            <p class="price">
                <span>{{ currencyAmount }} {{ selectCountryData.countryCurrency }}</span>
                <span v-if="showPointPay">{{ $t("LAN.either_or") }} {{ cnyAmount }}<img :src="pointIconImg"/></span>
            </p>
            <p class="tips">{{ $t("LAN.pay_tips") }}</p>
        </div>

        <!-- 最近支付列表 -->
        <div class="history" v-if="historyList.length > 0">
            <p class="select_tips">
                <span>{{ $t("LAN.pay_late_mode") }}</span>
            </p>
            <div
                v-for="(item, index) in historyList"
                :key="index"
                class="pay_box"
                @click="onPayOrder(item)"
            >
                <div class="pay_left">
                    <img alt="" :src="item.icon" @error="defImg()" />
                    <span :style="$L(1)">{{ getItemDiscountStr(item) }}</span>
                </div>
                <div class="pay_right">
                    <img :src=" $L() == 'L' ? require('@/assets/netmake/jt2.jpg') : require('@/assets/netmake/jt.jpg') " :class="$L()" alt="" />
                </div>
            </div>
        </div>

        <!-- 支付列表 -->
        <div class="history">
            <p class="select_tips">
                <span>{{ $t("LAN.pay_select_mode") }}</span>
            </p>
            <!-- <van-notice-bar
                left-icon="volume-o"
                text="2023-8-20~2023-8-23，活动期间使用mycard支付优惠10%"
            /> -->
            <div
                v-for="(item, index) in payList"
                :key="index"
                class="pay_box"
                @click="onPayOrder(item)"
            >
                <div class="pay_left">
                    <img alt="" :src="item.icon" @error="defImg()" />
                    <span :style="$L(1)" v-html="getItemDiscountStr(item)"></span>
                </div>
                
                <div class="pay_time" v-if="item.time > 0">
                    <div>剩餘時間</div>
                    <van-count-down :time="item.time">
                        <template #default="timeData">
                            <span class="block">{{ (timeData.hours + (timeData.days * 24)) }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ timeData.minutes }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ timeData.seconds }}</span>
                        </template>
                    </van-count-down>
                </div>

                <div class="pay_right">
                    <img :src=" $L() == 'L' ? require('@/assets/netmake/jt2.jpg') : require('@/assets/netmake/jt.jpg')" alt=""  />
                </div>
            </div>
        </div>

        <!-- 选择弹窗 -->
        <van-action-sheet v-model:show="languagePicker" style="height: 80%">
            <div class="language_box">
                
                <div class="language_top">
                    <div class="select">
                        <div @click="pickerTab = 1" :style="pickerTab == 1 ? 'color: #000;' : ''">{{ $t(`LAN.language`) }}</div>
                        <div @click="pickerTab = 2" :style="pickerTab == 2 ? 'color: #000;' : ''">{{ $t(`LAN.country`) }}</div>
                    </div>
                    <div v-show="pickerTab == 2">
                        <van-search
                            v-model="coutrySearchValue"
                            placeholder=""
                            @search="onCoutrySearch"
                        />
                    </div>
                    <div class="text" v-show="pickerTab == 2">{{ $t("LAN.select_pay_mode") }}</div>
                </div>

                <div class="language_t" v-show="pickerTab == 1">
                    <div v-for="(item, idx) in langColumns" :key="idx" @click="onLangConfirm(item)">
                        <span> {{item.name}} </span>
                        <span style="color: rgb(0, 119, 255)" v-show="item.lang == lang">{{ "√" }}</span>
                    </div>
                </div>
                <div class="language_t language_t3" v-show="pickerTab == 2">
                    <!-- 国家列表 -->
                    <div v-for="(item, idx) in searchCountryDatas" :key="idx" @click="selectCountry(item)">
                        <div class="T_img">
                            <img :src="item.countryLogo" alt="" />
                            <span> {{ item.countryName }}</span>
                        </div>
                        <span style="color: rgb(0, 119, 255)" v-show="item.country == selectCountryData.country">{{ "√" }}</span>
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>

    <!-- 游戏币输入密码弹窗 -->
    <div class="pwd-pop" v-if="showPointPwd">
        <div class="content">
            <div class="header">
                <div class="headicon" @click="showPointPwd = false">
                    <van-icon name="cross" />
                </div>
                <div class="header-name">{{ $t("LAN.input_pwd") }}</div>
            </div>
            <div>
                <p>{{ $t("LAN.add") }}</p>
                <p class="price">
                    <span>{{ currencyAmount }} {{ selectCountryData.countryCurrency }}</span>
                    <span> {{ $t("LAN.either_or") }} {{ cnyAmount }}<img :src="pointIconImg" alt="" /></span>
                </p>
            </div>

            <van-divider />

            <div class="pay-mode">
                <van-cell class="mode">
                    <span class="left">{{ $t("LAN.pay_mode") }}</span>
                    <span class="right">{{ $t("LAN.gamePoints") }}</span>
                    <img src="@/assets/common/points.png" alt="" />
                </van-cell>
            </div>

            <div class="password-input">
                <!-- 密码输入框 -->
                <van-password-input
                    :length="6"
                    :value="pointsPwd"
                    :focused="showKeyboard"
                    @focus="showKeyboard = true"/>
            </div>
            <div class="forgot">
                <a is-link @click="goTo('payForgot')">{{ $t("LAN.forget_pay") }}?</a>
            </div>
        </div>
        <!-- 数字键盘 -->
        <van-number-keyboard
            v-model="pointsPwd"
            :show="showKeyboard"
            @blur="showKeyboard = false"
        />
    </div>

    <!-- GZONE弹窗 -->
    <div class="pwd-pop" v-if="gzonePayData.showUI">
        <div class="content" style="top:40%">
            <div class="header">
                <div class="headicon" @click="gzonePayData.showUI = false">
                    <van-icon name="cross" />
                </div>
                <div class="header-name">{{ $t("LAN.wechat_native_1") }}</div>
            </div>
            <div>
                <p class="gzone-price">
                    <span>{{ gzonePayData.item_price }} Gạo</span>
                </p>
            </div>

            <van-divider />

            <div class="gzone-pay-mode">
                <van-cell class="mode">
                    <span class="left">{{ $t('LAN.account') }}</span>
                    <span class="right">{{ gzonePayData.username }}</span>
                </van-cell>
                <!-- <van-cell class="mode">
                    <span class="left">{{ $t('LAN.email') }}</span>
                    <span class="right">{{ gzonePayData.email }}</span>
                </van-cell> -->
                <van-cell class="mode">
                    <span class="left">{{ $t('LAN.exchange_balance') }}</span>
                    <span class="right">{{ gzonePayData.gzcoin }} Gạo</span>
                </van-cell>
                <van-cell class="mode">
                    <span class="left">{{ $t("LAN.pay_mode") }}</span>
                    <img src="@/assets/common/gzone.png"  alt=""/>
                </van-cell>
            </div>

            <div style="margin-top: 26px">
                <van-button round block class="gzone-but" @click="payGzone">{{ $t("LAN.confirm") }}</van-button>
            </div>
        </div>
    </div>

</template>

<script>
import sHeader from "@/components/Header";
import { reactive, onMounted, toRefs, getCurrentInstance, ref } from "vue";
import { Toast, Dialog } from "vant";
import axios from "axios";
import { setStore, getStore } from "@/utils/store";
import { validateNull } from "@/utils/validate";
import { useRoute, useRouter } from "vue-router";
import { PAY_MODE, CHANNEL, isDebug, isTest } from "@/configs/global";
import { useI18n } from "vue-i18n";
import { formate } from "@/utils/string";
import { useStore } from "vuex";
import { watch } from "vue";
import { callApp, openUrl } from "@/utils/common";

import { requestPay, requestPayPasswordVerify, requestPayGzone } from "@/service/pay";
import { requestExchangeRate } from "@/service/exchange";
import md5 from 'js-md5';
export default {
    setup() {
        // 扩展语言
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const store = useStore();
        const { t } = useI18n();
        const router = useRouter();

        const pointsPwd = ref("");

        const state = reactive({
            points: 0,

            initList: [],
            payList: [],
            historyList: [],

            // 订单价格
            cnyAmount: 1,
            currencyAmount: 1,

            languagePicker: false,
            pickerTab: 2,
            langColumns: [
                { name: t("LOCAL.zh_CN"), lang: "zh_CN" },
                { name: t("LOCAL.zh_TW"), lang: "zh_TW" },
                { name: t("LOCAL.en_US"), lang: "en_US" },
                { name: t("LOCAL.ko_KR"), lang: "ko_KR" },
                { name: t("LOCAL.ru_RU"), lang: "ru_RU" },
                { name: t("LOCAL.de_DE"), lang: "de_DE" },
                { name: t("LOCAL.ja_JP"), lang: "ja_JP" },
                { name: t("LOCAL.tr_TR"), lang: "tr_TR" },
                { name: t("LOCAL.pt_BR"), lang: "pt_BR" },
                { name: t("LOCAL.vi_VN"), lang: "vi_VN" },
                { name: t("LOCAL.es_LA"), lang: "es_LA" },
                { name: t('LOCAL.ar_SA'), lang: "ar_SA"}
            ],

            countryDatas: [],
            searchCountryDatas:[],
            coutrySearchValue: "",

            selectCountryData:{
                "country": "US",
                "countryName": "United States of America",
                "countryCurrency": "USD",
                "countryLogo": '/img/national_flag/US.png',
                "currencyRate": 7.2891
            },

            currencyPayCfg:[],

            lang: store.getters.lang,

            showPointPay: false,
            pointIconImg:  "/img/payicon/points-icon.png",
            defaultImg: require("@/assets/common/null.png"),

            discountList: [],
            modeList: [],

            showBack: false,
            showPointPwd: false,
            showKeyboard: false,
            server: 0,
            orderNo: "",

            gzonePayData: {
                showUI:false,
                id: 0,
                email: "",
                username: "",
                token: "",
                item_price:0,
                gzcoin:0
            }
        });

        onMounted(() => {
            if (Object.keys(route.query).length > 0) {
                store.dispatch("initGame", route.query);
                store.dispatch("initUser", route.query);
                store.dispatch("initGameRole", route.query);
                store.dispatch("initPay", route.query);
            }
            proxy.$I(proxy, store.getters.lang);

            state.discountList = decodeURI(store.getters.discountList).split("|");
            state.modeList = decodeURI(store.getters.mode).split("|");
            state.cnyAmount = store.getters.amount;
            state.currencyAmount = state.cnyAmount;
            state.showBack = store.getters.showBack == 1;
            state.points = store.getters.points || decodeURI(store.getters.points);

            initPayList();
            init();
        });

        const initPayList = () => {
            state.initList = [
                {
                    mode: PAY_MODE.GZONE,
                    name: "Gzone Pay",
                    icon:  "/img/payicon/gzone.png",
                },
                {
                    mode: PAY_MODE.POINTS,
                    name: t("LAN.gamePoints"),
                    icon: "/img/payicon/points.png",
                },
                {
                    mode: PAY_MODE.JINGXIU,
                    modeid: "wechatLiteH5",
                    name: t("LAN.wechat"),
                    icon:  "/img/payicon/wechat.png",
                },
                {
                    mode: PAY_MODE.WECHAT,
                    name: t("LAN.wechat"),
                    modeid: "MWEB",
                    icon:  "/img/payicon/wechat.png",
                },
                {
                    mode: PAY_MODE.MIHUA,
                    name: t("LAN.wechat"),
                    icon: "/img/payicon/wechat.png",
                },
                {
                    mode: PAY_MODE.ALIPAY,
                    name: t("LAN.alipay"),
                    icon:  "/img/payicon/alipay.png",
                },
                {
                    mode: PAY_MODE.PAYPAL,
                    name: "Paypal Pay",
                    icon:  "/img/payicon/paypal.png",
                },
                {
                    mode: PAY_MODE.PAYPAL,
                    name: "Credit/Debit Cards（PayPal）",
                    icon:  "/img/payicon/worldpay.png",
                    modeid: 3035,
                },
                {
                    mode: PAY_MODE.ADYEN,
                    name: "Credit/Debit Cards (Adyen)",
                    icon: "/img/payicon/adyen.png",
                },
                {
                    mode: PAY_MODE.STRIPE,
                    name: "Credit/Debit Cards (Stripe)",
                    icon:  "/img/payicon/xsolla1380.png",
                },
                {
                    mode: PAY_MODE.AIRWALLEX,
                    name: "Credit/Debit Cards (Airwallex)",
                    icon:  "/img/payicon/airwallex.png",
                },
                // {
                //     mode: PAY_MODE.PAYERMAX,
                //     name: "Visa/Mastercard/AE/JCB",
                //     icon: process.env.BASE_URL + "/img/payicon/payermax/Global/VISA_MasterCard_AE_C_A_2x.png",
                // },
                {
                    mode: PAY_MODE.XSOLLA,
                    modeid: 1380,
                    name: "Credit/Debit Cards (Xsolla)",
                    icon:  "/img/payicon/xsolla1380.png",
                },
                {
                    mode: PAY_MODE.WORLDPAY,
                    name: "Visa/Mastercard/AE/JCB (WorldPay)",
                    icon:  "/img/payicon/worldpay.png",
                },
                {
                    mode: PAY_MODE.XSOLLA,
                    modeid: 3431,
                    name: "Google Pay",
                    icon:  "/img/payicon/xsolla3431.png",
                },
                {
                    mode: PAY_MODE.XSOLLA,
                    modeid: 3175,
                    name: "Apple Pay",
                    icon:  "/img/payicon/xsolla3175.png",
                },
                {
                    mode: PAY_MODE.XSOLLA,
                    modeid: 235,
                    name: "Amazon Pay",
                    icon:  "/img/payicon/xsolla235.png",
                },
                {
                    mode: PAY_MODE.PACYPAY,
                    name: "Credit Card (Pacypay)",
                    icon:  "/img/payicon/pacypay.png",
                },
                // {
                //     mode: PAY_MODE.PAYERMAX,
                //     name: "payermax MY|CARD",
                //     icon: process.env.BASE_URL + "/img/payicon/payermax.png",
                //     modeid:"|CARD",
                // },
                {
                    mode: PAY_MODE.PAYERMAX,
                    name: t("LAN.pay_more") + " (Payermax)",
                    icon:  "/img/payicon/payermax.png",
                },
                {
                    mode: PAY_MODE.XSOLLA,
                    name: t("LAN.pay_more") + " (Xsolla)",
                    icon:  "/img/payicon/xsolla.png",
                },
            ];

            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

            var isPCChanel = store.getters.channel == CHANNEL.PC || store.getters.channel == CHANNEL.MICROSOFT;
            for (let i = state.initList.length - 1; i >= 0; i--) {
                let pay = state.initList[i];
                let name = pay.name.toLowerCase();
                if (!isPCChanel) {
                    if (name.indexOf("amazon") > -1) {
                        state.initList.splice(i, 1);
                        break;
                    }
                }
                if (!isiOS) {
                    if (name.indexOf("apple") > -1) {
                        state.initList.splice(i, 1);
                        break;
                    }
                }
            }

            if (isPCChanel) {
                for (let i = state.modeList.length - 1; i >= 0; i--) {
                    if (state.modeList[i] == PAY_MODE.ALIPAY) {
                        state.modeList.splice(i, 1);
                    }
                }
                for (let i = state.initList.length - 1; i >= 0; i--) {
                    let pay = state.initList[i];
                    if (pay.mode == PAY_MODE.WECHAT) {
                        pay = {
                            mode: PAY_MODE.WECHAT,
                            name: t("LAN.wechat"),
                            modeid: "NATIVE",
                            icon: "/img/payicon/wechat.png",
                        };
                        state.initList[i] = pay;
                    } else if (pay.mode == PAY_MODE.JINGXIU) {
                        pay = {
                            mode: PAY_MODE.JINGXIU,
                            name: t("LAN.wechat"),
                            modeid: "wechatQr",
                            icon:  "/img/payicon/wechat.png",
                        };
                        state.initList[i] = pay;
                    }
                }
            }

            state.showPointPay = hasMode(PAY_MODE.POINTS);
            
            for (let i = state.initList.length - 1; i >= 0; i--) {
                let pay = state.initList[i];
                if (!hasMode(pay.mode)) {
                    state.initList.splice(i, 1);
                }
            }

            if (isTest || isDebug) {
                state.initList.unshift({
                    mode: PAY_MODE.PAYSSION,
                    modeid: "payssion_test",
                    name: "Payssion 测试",
                    icon:  "/img/payicon/test.png",
                });
            }

            state.payList = state.initList.concat();


            payHistory();
        }

        const init = async () => {
            watch(pointsPwd, () => {
                if (pointsPwd.value.length > 6) {
                    pointsPwd.value = pointsPwd.value.slice(0, 6);
                }
                if ( pointsPwd.value.length === 6 && !validateNull(state.orderNo) ) {
                    payVerify(pointsPwd.value);
                }
            });

            // 国家列表        
            let result = await axios.get(
               "/data/pay/country.json?v=13"
            );
            let countryDatas = [];
            for (const item of result.data) {
                if (item.show == "1") {
                    item.countryLogo =  '/img/national_flag/' + item.countryLogo
                    countryDatas.push(item)
                }
            }
            state.countryDatas = countryDatas;
            state.searchCountryDatas = countryDatas.concat();
            
            // 支付列表
            let currencyPayCfgResult = await axios.get(
                 "/data/pay/countryPay.json?v=13"
            );
            state.currencyPayCfg = currencyPayCfgResult.data

            let selectCountryData = getStore({ name: "selectCountryData" }) || [];
            if (typeof selectCountryData == "string") {
                state.selectCountryData = JSON.parse(selectCountryData);
                state.currencyRate = state.selectCountryData.currencyRate
                state.currencyAmount = (state.cnyAmount / state.currencyRate).toFixed(2)
                updateCurrency();
            } else if (store.getters.currency != "CNY") {
                for (const item of state.countryDatas) {
                    if (item.countryCurrency == store.getters.currency) {
                        state.selectCountryData = item;
                        break;
                    }
                }
                updateCurrency();
            } else {
                let country_result = await axios.get("https://ipinfo.io/json");
                console.log(country_result.data)
                for (const item of state.countryDatas) {
                    if (item.country == country_result.data.country) {
                        state.selectCountryData = item;
                        break;
                    }
                }
                updateCurrency();
            }
        };

        // 支付历史
        const payHistory = () => {
            let payHistoryList = getStore({ name: "payHistory" }) || [];
            if (typeof payHistoryList == "string") {
                payHistoryList = JSON.parse(payHistoryList);
            }
            state.historyList = [];
            for (const key in payHistoryList) {
                let pay = payHistoryList[key];
                if (hasMode(pay.mode)) {
                    if (pay.mode == PAY_MODE.POINTS) {
                        pay.name = pay.name + "(" + t("LAN.remain") + state.points + ")";
                    }
                    state.historyList.push(createPayMode(pay));
                }
                if (state.historyList.length >= 1) {
                    break;
                }
            }
        };

        const languageIs = () => {
            state.languagePicker = true;
        };

        const onLangConfirm = (res) => {
            state.lang = res.lang;
            proxy.$I(proxy, res.lang);
            state.languagePicker = false;
            // 重刷下列表
            initPayList()
            updateCurrency()
        };

        // 搜索国家
        const onCoutrySearch = (res) => {
            let list = [];
            res = res.toLowerCase()
            for (const item of state.countryDatas) {
                if (res == "" 
                || item.countryName.toLowerCase().indexOf(res) > -1 
                || item.country.toLowerCase().indexOf(res) > -1 
                || item.countryCurrency.toLowerCase().indexOf(res) > -1) {
                    list.push(item);
                }
            }
            // 选中的国家排在最前
            list.sort((a,b)=>{
                let aState = a.country == state.selectCountryData.country ? 1 : 0;
                let bState = b.country == state.selectCountryData.country ? 1 : 0;
                return bState - aState;
            })
            state.searchCountryDatas = list;
        }

        const selectCountry = (res) => {
            state.selectCountryData = res
            state.languagePicker = false;
            updateCurrency()
        };

        const savePayHistory = (pay) => {
            let payHistoryList = getStore({ name: "payHistory" }) || [];
            if (typeof payHistoryList == "string") {
                payHistoryList = JSON.parse(payHistoryList);
            }
            var appendFlag = true;
            payHistoryList.forEach(function (mode) {
                if (mode === pay) {
                    appendFlag = false;
                    return;
                }
            });
            // 判断-添加
            if (appendFlag === true) {
                // 长度判断
                if (payHistoryList.length >= 1) {
                    payHistoryList.unshift(pay);
                    payHistoryList.pop();
                } else {
                    payHistoryList.unshift(pay);
                }
                setStore({
                    name: "payHistory",
                    content: JSON.stringify(payHistoryList),
                });
            }
        };

        const hasMode = (mode) => {
            if (!mode) {
                return false;
            }
            for (let i = 0; i < state.modeList.length; i++) {
                if (
                    mode == state.modeList[i] ||
                    mode.indexOf(state.modeList[i]) == 0
                ) {
                    return true;
                }
            }
            return false;
        };

        const getItemDiscountStr = (pay) => {
            if (pay.mode == PAY_MODE.POINTS) {
                return pay.name + "(" + t("LAN.remain") + state.points + ")";
            }
            for (const str of state.discountList) {
                let ary = str.split("_");
                let name = ary[0];
                if (name == pay.mode) {
                    let discount = ary[1];
                    if (discount > 0) {
                        return (
                            pay.name + formate(t("LAN.pay_discount"), discount)
                        );
                    }
                }
            }
            return pay.name;
        };

        const updateCurrency = async () => {
            // 支付列表
            let payList = state.initList.concat();

            for (const item of state.currencyPayCfg) {
                if (item.country == state.selectCountryData.country) {
                    item.minPrice = item.minPrice && item.minPrice != "" ? item.minPrice : 0;
                    item.maxPrice = item.maxPrice && item.maxPrice != "" ? item.maxPrice : 0;
                    if ((item.minPrice && state.currencyAmount < parseFloat(item.minPrice)) || (item.maxPrice && state.currencyAmount > parseFloat(item.maxPrice))) {
                        continue;
                    }
                    console.log(state.currencyAmount, item.minPrice, item.maxPrice)
                    if (item.show == "1" && hasMode(item.mode)) {
                        payList.splice(payList.length - 2, 0, createPayMode(item))
                    }
                }
            }
            
            state.payList = payList;
            
            const exchangeResult = await requestExchangeRate( state.selectCountryData.countryCurrency );
            state.currencyRate = exchangeResult.rate
            state.currencyAmount = (state.cnyAmount / state.currencyRate).toFixed(2)
            state.selectCountryData.currencyRate = state.currencyRate
            setStore({
                name: "selectCountryData",
                content: JSON.stringify(state.selectCountryData),
            });

            // 选中的国家排在最前
            let list = state.countryDatas.concat();
            list.sort((a,b)=>{
                let aState = a.country == state.selectCountryData.country ? 1 : 0;
                let bState = b.country == state.selectCountryData.country ? 1 : 0;
                return bState - aState;
            })
            state.searchCountryDatas = list;
        };

        const createPayMode = (pay) => {
            let icon = "";
            let has = false;
            for (let i = 0; i < state.initList.length; i++) {
                const def = state.initList[i];
                if (pay.mode == def.mode && pay.modeid == def.modeid) {
                    pay = def;
                    icon = pay.icon;
                    has = true;
                    break;
                }
            }
            if (!has) {
                if (pay.mode == "xsolla") {
                    icon = `https://cdn3.xsolla.com/paymentoptions/paystation/theme_33/69x50/${pay.modeid}@2x.png`;
                } else if (pay.mode == "payssion") {
                    icon = `https://raw.githubusercontent.com/payssion/payssion-payment-logos/master/${pay.modeid}.png`;
                } else if (pay.mode == "payermax") {
                    icon =  "/img/payicon/payermax/" + pay.icon;
                } else {
                    let mid = pay.modeid ? pay.modeid : "";
                    icon =  `/img/payicon/${pay.mode + mid}.png`;
                }
            }

            let time = 0;
            if (pay.mode == PAY_MODE.MYCARD) {
                let startTimeStamp = Date.parse('2023-9-25');
                let endTimeStamp = Date.parse('2023-10-22');
                let curTimeStamp = new Date().getTime();
                if (curTimeStamp >= startTimeStamp) {
                    time = endTimeStamp - curTimeStamp;
                    if (time > 0) {
                        pay.name = pay.name + "<font color='#d71c22'>(多返10%元寶)</font>"
                    }
                }
                console.log(time)
            }

            return {
                mode: pay.mode,
                modeid: pay.modeid,
                name: pay.name,
                icon: icon,
                time: time,
            };
        };

        const onPayOrder = async (pay) => {
            let mode = pay.mode;

            if (mode == PAY_MODE.POINTS) {
                // 游戏点数余额不足
                if (parseInt(state.points) < parseInt(store.getters.amount)) {
                    Dialog.confirm({
                        cancelButtonText: t("LAN.cancel"),
                        confirmButtonText: t("LAN.pay_go"),
                        title: t("LAN.balance"),
                        // message: t('LAN.balance'),
                        closeOnClickOverlay: true,
                        confirmButtonColor: "#6e87ea",
                    })
                        .then(async () => {
                            goTo("points");
                        })
                        .catch(() => {});
                    return;
                }
            }

      
            Toast.loading(t("LAN.pay_paying"));
            let modeid = "";
            if (!validateNull(pay.modeid)) {
                modeid = pay.modeid;
            }

            savePayHistory({
                mode: pay.mode,
                modeid: pay.modeid,
                name: pay.name,
            });
        var params =    {
                mode: mode,
                gameId: route.query.gameId,
                server: 1,
                roleId: store.getters.roleId,
                rolename: store.getters.roleName,
                level: store.getters.roleLevel,
                channel: store.getters.channel,
                outOrderNo: OrderNumber(),
                amount: store.getters.amount,
                productId: decodeURI(store.getters.productId),
                extInfo: modeid,
                //获取浏览器属性进行MD5加密后的字符
                browserMarking: browser(),
                sandbox: store.state.app.sandbox
            }
            console.log(store.state.app.sandbox);
            if(mode == PAY_MODE.PAYPAL && params.extInfo == 3035){
                //跳转到指定页面
                location.href = "/card/index.html?mode="+params.mode+"&gameId="+params.gameId+"&channel="+params.channel+"&outOrderNo="+params.outOrderNo+"&amount="+params.amount+"&productId="+params.productId+"&token="+store.getters.token+"&browserMarking="+params.browserMarking+"&locale="+store.getters.lang+"&sandbox="+store.state.app.sandbox;
                return;
            }

            const result = await requestPay(params);
            Toast.clear();

            let orderNo = result.orderNo;
            var info = {
                orderNo: orderNo,
                productId: decodeURI(store.getters.productId),
                amount: store.getters.amount,
            };

            if (store.getters.gameId != 1040) {
                callApp("doPayOrder", JSON.stringify(info));
            }
    
            if (result.token !== "") {
                switch (pay.mode) {
                    case PAY_MODE.MIHUA:
                    case PAY_MODE.WECHAT:
                        if (modeid == "NATIVE") {
                            goTo("wechatNativeLink", {
                                wechat_url: result.token,
                                amount_str: state.cnyAmount +  " CNY"
                            });
                            return;
                        }
                        if (
                            store.getters.channel == CHANNEL.H5 ||
                            store.getters.channel == CHANNEL.COPY_IOS ||
                            store.getters.channel == CHANNEL.COPY_IOS2
                        ) {
                            var reg = new RegExp("&", "g");
                            var wechatUrl = result.token.replace(reg, "*");
                            // location.href = "http://www.handzone.xyz/wechat.php?wechat_url=" + wechatUrl;
                            const iframe = document.createElement("iframe");
                            iframe.style.display = "none";
                            iframe.setAttribute(
                                "src",
                                "https://sdk.handzone.xyz/api/pay/wechat.php?wechat_url=" +
                                    wechatUrl
                            );
                            iframe.setAttribute(
                                "sandbox",
                                "allow-top-navigation allow-scripts"
                            );
                            document.body.appendChild(iframe);
                            setTimeout(function () {
                                iframe.parentNode.removeChild(iframe);
                                goTo("payReturnLink", {
                                    out_trade_no: orderNo,
                                });
                            }, 3000);
                        } else {
                            location.href = result.token;
                        }
                        break;
                    case PAY_MODE.ALIPAY:
                        document.writeln(result.token);
                        break;
                    case PAY_MODE.AIRWALLEX:
                        {
                            let originUrl =    document.location.origin +  `/sdkweb/paymode/airwallex/index.html?token=` +    encodeURI(result.token);
                            let localUrl = `/paymode/airwallex/index.html?token=` +   encodeURI(result.token);

                            if (
                                store.getters.channel == CHANNEL.APPLE_STORE ||
                                store.getters.channel == CHANNEL.ZY_IOS
                            ) {
                                let resultStr = callApp(
                                    "openSafariWebView",
                                    originUrl
                                );
                                if (resultStr != "") {
                                    goTo("payReturnLink", {
                                        out_trade_no: orderNo,
                                    });
                                } else {
                                    location.href = localUrl;
                                }
                            } else if (
                                store.getters.channel == CHANNEL.H5 ||
                                store.getters.channel == CHANNEL.COPY_IOS ||
                                store.getters.channel == CHANNEL.COPY_IOS2 ||
                                store.getters.channel == CHANNEL.PC ||
                                store.getters.channel == CHANNEL.MICROSOFT
                            ) {
                                location.href = localUrl;
                            } else {
                                openUrl(originUrl);
                                goTo("payReturnLink", {
                                    out_trade_no: orderNo,
                                });
                            }
                        }
                        break;
                    case PAY_MODE.STRIPE:
                        if (isDebug) {
                            location.href =
                                process.env.BASE_URL +  `paymode/stripe/index.html?token=` +  result.token;
                        } else {
                            location.href =
                                "https://www.handzonegame.com/sdkweb/paymode/stripe/index.html?token=" +
                                result.token;
                        }
                        break;
                    case PAY_MODE.POINTS:
                        state.orderNo = result.token;
                        pointsPwd.value = "";
                        state.showPointPwd = true;
                        state.showKeyboard = true;
                        break;
                    case PAY_MODE.PACYPAY:
                        location.href = decodeURI(result.token);
                        break;
                    case PAY_MODE.JINGXIU:
                        {
                            if (
                                store.getters.channel == CHANNEL.PC ||
                                store.getters.channel == CHANNEL.MICROSOFT
                            ) {
                                open(result.token);
                                goTo("payReturnLink", {
                                    out_trade_no: orderNo,
                                });
                            } else {
                                if (store.getters.channel == CHANNEL.H5) {
                                    var u = navigator.userAgent;
                                    var isiOS = !!u.match(
                                        /\(i[^;]+;( U;)? CPU.+Mac OS X/
                                    ); //ios终端
                                    if (isiOS) {
                                        window.top.postMessage(
                                            {
                                                msg: "hzsdkPay",
                                                success: true,
                                                pay_url: result.token,
                                            },
                                            "*"
                                        );
                                    } else {
                                        location.href = result.token;
                                    }
                                } else {
                                    location.href = result.token;
                                }
                                goTo("payReturnLink", {
                                    out_trade_no: orderNo,
                                });
                            }
                        }
                        break;
                    case PAY_MODE.ADYEN:
                        {
                            let originUrl =
                                document.location.origin +  `/sdkweb/paymode/adyen/index.html?token=` +
                                encodeURI(result.token);
                            let localUrl =    `/paymode/adyen/index.html?token=` +
                                encodeURI(result.token);

                            // originUrl = 'http://test.handzone.xyz/adyen/index.html?token=' + encodeURI(result.token);
                            // localUrl = 'http://test.handzone.xyz/adyen/index.html?token=' + encodeURI(result.token);

                            if (
                                store.getters.channel == CHANNEL.APPLE_STORE ||
                                store.getters.channel == CHANNEL.ZY_IOS
                            ) {
                                let resultStr = callApp(
                                    "openSafariWebView",
                                    originUrl
                                );
                                if (resultStr != "") {
                                    goTo("payReturnLink", {
                                        out_trade_no: orderNo,
                                    });
                                } else {
                                    location.href = localUrl;
                                }
                            } else if (
                                store.getters.channel == CHANNEL.H5 ||
                                store.getters.channel == CHANNEL.COPY_IOS ||
                                store.getters.channel == CHANNEL.COPY_IOS2 ||
                                store.getters.channel == CHANNEL.PC ||
                                store.getters.channel == CHANNEL.MICROSOFT
                            ) {
                                location.href = localUrl;
                            } else {
                                openUrl(originUrl);
                                goTo("payReturnLink", {
                                    out_trade_no: orderNo,
                                });
                            }
                        }
                        break;
                    case PAY_MODE.WORLDPAY:
                        {
                            if (
                                store.getters.channel == CHANNEL.H5 ||
                                store.getters.channel == CHANNEL.COPY_IOS ||
                                store.getters.channel == CHANNEL.COPY_IOS2 ||
                                store.getters.channel == CHANNEL.PC ||
                                store.getters.channel == CHANNEL.MICROSOFT
                            ) {
                                openUrl(result.token);
                                goTo("payReturnLink", {
                                    out_trade_no: orderNo,
                                });
                                return;
                            }


                            window.open(result.token);
                            console.log( result.token)
                          //  location.href = result.token;
                        }
                        break;
                    case PAY_MODE.GZONE:
                        state.orderNo = result.orderNo;
                        state.gzonePayData = JSON.parse(result.token).data
                        state.gzonePayData.showUI = true
                        break;
                    default:
                        {
                            console.log(result.token)
                            let name = pay.name.toLowerCase();
                            if (name.indexOf("google") > -1 || name.indexOf("apple") > -1) {
                                if (!(/Safari/.test(navigator.userAgent))) {
                                console.log(result.token)
                                openUrl(result.token);
                                goTo("payReturnLink", { out_trade_no: orderNo });
                                return;
                                }
                            }
                            location.href = result.token;
                        }
                        break;
                }
            } else {
                Toast.fail(t("LAN.pay_fail"));
            }
        };

        //验证支付密码
        const payVerify = async (params) => {
            Toast.loading(t("LAN.pay_paying"));
            await requestPayPasswordVerify({
                userId: store.getters.userId,
                payPassword: params,
                orderNo: state.orderNo,
                gameId: store.getters.gameId,
            });
            Toast.clear();
            goTo("payReturnLink", { out_trade_no: state.orderNo });
        };

        // gzone确认支付
        const payGzone = async () => {
            Toast.loading(t("LAN.pay_paying"));
            if (state.gzonePayData.gzcoin < state.gzonePayData.item_price) {
                Toast.msg('Vui lòng nạp thêm GẠO tại nap.zoneplay.vn')
                return
            }
            await requestPayGzone({
                orderNo: state.orderNo,
                token: state.gzonePayData.token,
            });
            Toast.clear();
            goTo("payReturnLink", { out_trade_no: state.orderNo });
        };

        const defImg = () => {

            let img = event.srcElement;
            img.src = state.defaultImg;
            img.onerror = null; //防止闪图
        };

        const goTo = (name, params) => {
            router.push({ name: name, query: params });
        };
        const  OrderNumber = ()=>{
            const timestamp = new Date().getTime();
            const random = Math.floor(Math.random() * 1000000);
            return `ORDER${timestamp}${random}`;
        }
        const browser = ()=>{
                var cookieEnabled = navigator.cookieEnabled; // 返回用户浏览器是否启用了cookie
                var cpuClass = navigator.cpuClass; //返回用户计算机的cpu的型号，通常intel芯片返回"x86"（火狐没有）
                //   Win32(windows98,Me,NT,2000,xp),Mac68K(Macintosh 680x0)
                //     和ＭacPPC(Macintosh PowerPC)
                var userLanguage = navigator.userLanguage; // 用户在自己的操作系统上设置的语言（火狐没有）
                var userAgent = navigator.userAgent; //包含以下属性中所有或一部分的字符串：appCodeName,appName,appVersion,language,platform
                var systemLanguage = navigator.systemLanguage; // 用户操作系统支持的默认语言（火狐没有）
                var appCodeName = navigator.appCodeName; //与浏览器相关的内部代码名
                var appMinorVersion = navigator.appMinorVersion; //辅版本号（通常应用于浏览器的补丁或服务包)
                var language = navigator.language; //浏览器支持的语言 （IE没有）
                var onLine = navigator.onLine; //返回浏览器是否处于在线模式（IE4以上版本）
                var opsProfile = navigator.opsProfile; // 未定义   （IE、火狐没有）
                var oscpu = navigator.oscpu; //浏览器正在运行的操作系统，其中可能有CPU的信息（IE没有）
                var product = navigator.product; // 浏览器的产品名（IE没有）
                var productSub = navigator.productSub; //关于浏览器更多信息（IE没有）
                var securityPolicy = navigator.securityPolicy; // 浏览器支持的加密类型（IE没有）
                var userProfile = navigator.userProfile; //  返回一个UserProfile对象，它存储用户的个人信息（火狐没有）
                var vender = navigator.vender; // 浏览器厂商名称（IE、火狐没有）
                return md5(cookieEnabled+cpuClass+userLanguage+userAgent+systemLanguage+appCodeName+appMinorVersion+language+onLine+opsProfile+oscpu+product+productSub+securityPolicy+userProfile+vender);
        };
        return {
            pointsPwd,
            ...toRefs(state),
            onLangConfirm,
            languageIs,
            selectCountry,
            updateCurrency,
            onPayOrder,
            defImg,
            getItemDiscountStr,
            goTo,
            payVerify,
            payGzone,
            onCoutrySearch
        };
    },
    components: {
        sHeader,
    },
};
</script>

<style scoped lang="less">
.payment {
    height: 100vh;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);overflow-y: scroll;
    p {
        margin: 0px;
    }

    .top {
        background: #ffffff;

        .zfbt {
            padding-top: 5px;
            text-align: center;
            font-size: 16px;
            color: #333;
            height: 50px;
            // line-height:50px;
            .bcxf {
                display: inline;
            }
            .change {
                position: absolute;
                right: 10px;
                .change_t {
                    display: flex;
                    align-items: center;
                    img {
                        width: 20px;
                        margin-right: 3px;
                    }
                    a {
                        color: #333;
                        text-decoration: underline;
                    }
                }
            }
        }

        .price {
            text-align: center;
            font-size: 22px;
            height: 22px;
            margin-top: -5px;
            line-height: 22px;
            color: #6e87ea;
            img {
                height: 20px;
            }
        }
        .tips {
            color: #111111;
            font-size: 13px;
            text-align: center;
            margin-top: 10px;
            padding-bottom: 10px;
        }
    }
    .history {
        width: 100%;
        background-color: #fff;
        .pay_box {
            padding: 2px 10px;
            margin: 0 auto;
            border-bottom: 1px solid rgb(245, 241, 241);
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .pay_left {
                width: 90%;
                padding: 10px 0;
                display: flex;
                align-items: center;
                text-align: left;
                img {
                    width: 40px;
                    height: auto;
                    margin: auto 0;
                }
                span {
                    width: 82%;
                    font-size: 17px;
                    line-height: 25px;
                    margin: 0 6px;
                    word-wrap: break-word;
                    word-break: normal;
                }
            }
            .pay_time{
                width: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: left;
                font-size: 14px;
                color: #d71c22;
                padding-bottom: 4px;
            }
            .pay_right {
                img {
                    width: 16px;
                }
                .L {
                    width: 12px;
                }
            }
        }
    }
    .select_tips {
        height: 36px;
        background: #efefef;
        border: 1px solid #ebebeb;
        color: #a7a7a7;
        text-align: center;
        font-size: 16px;
        line-height: 36px;
    }

    .item {
        display: flex;
        align-content: center;
        .icon {
            width: 55.2px;
            height: 40px;
            margin: auto 0;
        }
        .text {
            font-size: 17px;
            line-height: 40px;
            margin-left: 5px;
        }
    }
    .language_box {
        .language_top {
            width: 100%;
            position: fixed;
            .select {
                width: 100%;
                display: flex;
                background-color: #fff;
                border-radius: 19px 19px 0 0;
                align-items: center;
                height: 50px;
                div {
                    margin-left: 20px;
                    color: #999;
                    font-size: 16px;
                }
            }
            .text {
                width: 100%;
                line-height: 45px;
                background: #cffaef;
                color: #006c71;
                font-size: 15px;
                align-items: center;
            }
        }
        .language_t {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            text-align: left;
            padding-top: 60px;
            div {
                width: 90%;
                margin: 0 auto;
                border-bottom: 1px solid #f3efef;
                font-weight: 400;
                color: #333;
                font-size: 15px;
                line-height: 50px;
                display: flex;
                justify-content: space-between;
            }
        }
        .language_t2 {
            padding-top: 100px;
            .T_img {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 0;
                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 15px;
                }
            }
        }
        .language_t3 {
            padding-top: 150px;
            .T_img {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 0;
                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 15px;
                }
            }
        }
    }
}

.pwd-pop {
    font-size: 15px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0%;
    z-index: 1600;

    // display: flex;
    // align-items: center;
    // flex-flow: column;

    position: fixed;

    .content {
        width: 80%;
        max-width: 400px;
        position: absolute;
        // margin-top: 15%;
        background: #ffffff;
        border-radius: 5px;
        padding: 20px;

        top: 30%;
        left: 50%;
        -webkit-transform: translate(-50%, -30%);
        transform: translate(-50%, -30%);

        p {
            margin: 10px;
        }
        .price {
            font-size: 25px;
            text-align: center;
            color: #6e87ea;
        }

        .header {
            .headicon {
                position: absolute;
                font-size: 25px;
            }
            .header-name {
                text-align: center;
                font-size: 20px;
                line-height: 30px;
            }
        }

        .pay-mode {
            // float: left;
            .mode {
                padding: 0;
                color: #a7a7a7;
                .left {
                    float: left;
                    line-height: 20px;
                    color: #000;
                }
                .right {
                    float: right;
                    line-height: 20px;
                    color: #000;
                }
                img {
                    height: 20px;
                    float: right;
                    // border: #111111 solid 1px;
                    vertical-align: -4px;
                }
            }
        }


        .gzone-price {
            font-size: 25px;
            text-align: center;
            color: #d71c22;
        }

        .gzone-pay-mode {
            // float: left;
            .mode {
                padding: 0;
                color: #a7a7a7;
                .left {
                    float: left;
                    line-height: 30px;
                    color: #000;
                }
                .right {
                    float: right;
                    line-height: 30px;
                    color: #000;
                }
                img {
                    height: 30px;
                    float: right;
                    vertical-align: -4px;
                }
            }
        }

        .password-input {
            margin-top: 20px;
        }
        .forgot {
            margin-top: 20px;
            font-size: 16px;

            a {
                text-decoration: underline;
            }
        }
    }

    .gzone-but {
        background-color: #d71c22;
        color: #fff;
        border: #1d1d1d;
    }
}

.colon {
    display: inline-block;
    margin: 0 1px;
    color: #d71c22;
}
.block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #d71c22;
}
</style>
